<template>
	<div>
		<div class="panel m-5">
			<PanelHeader panel-header="Kasseninformationen" />
			<div class="container pt-3 pb-3">
				<div class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Namen ein!" :name="name"
						:set-name="(val) => (name = val)" label="Kassennamen" />
					<InputWithValidation class="column" message="Bitte geben Sie eine Kassennummer ein!" :name="num"
						:set-name="(val) => (num = val)" label="Kassennummer" type="number" />
					<InputComponent   class="column" :name="gegenkonto"
						:set-name="(val) => (gegenkonto = val)" label="Gegenkonto (Export)" type="number"/>
					<div class="column">
						<b-field>
							<template #label>
								<div class="align-content-center" style="display: inline-flex">
									<p> Darf das Konto ins Minus gehen? </p>
								</div>
							</template>
							<b-checkbox class="m-2 has-text-left" v-model="allowNegativeValue">
								Ja
							</b-checkbox>
						</b-field>
					</div>
					<InputWithValidation v-if="false" class="column"
						message="Bitte geben Sie eine Frist in Tagen zwischen Belegdatum und Buchungsdatum ein!"
						:name="deadline" :set-name="(val) => (deadline = val)" label="Buchungsfrist" type="number" />
				</div>
				<div v-if="false" class="columns">
					<InputWithValidation class="column" message="Bitte geben Sie einen Hinweis ein!" :name="description"
						:set-name="(val) => (description = val)" label="Hinweis" />
				</div>
			</div>
		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Konten" />
			<div class="container pt-3 pb-3">
				<AccountList label="Konten" :input-list="accountList"
					@filter="id => accountList = accountList.filter(acc => acc.id !== id)" />
				<p v-if="!accountListValid" class="help is-danger">
					Bitte erstellen Sie mindestens ein Konto.
				</p>
			</div>
		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Budgets" />
			<div class="container pt-3 pb-3">
				<BudgetList label="Budgets" :input-list="budgetList"
					@filter="id => budgetList = budgetList.filter(budget => budget.id !== id)" />
				<p v-if="!budgetListValid" class="help is-danger">
					Bitte erstellen Sie mindestens ein Budget.
				</p>
			</div>
		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Verantwortlicher" />
			<div class="container pt-3 pb-3">
				<SingleUserSelection v-model="chosenManager" />
				<p v-if="!chosenManagerValid" class="help is-danger">
					Bitte wählen Sie einen Verantwortlichen aus.
				</p>
			</div>

		</div>
		<div class="panel m-5">
			<PanelHeader panel-header="Gruppe/Kinder/Mitarbeiter" />
			<div class="container pt-3 pb-3">
				<GroupSelection :chosen-group="chosenGroup" :chosen-clients="chosenClients" :chosen-users="chosenUsers"
					@setGroup="(group) => chosenGroup = group" @setClients="(clients) => chosenClients = clients"
					@setUsers="(users) => chosenUsers = users" />
				<p v-if="!chosenGroupValid" class="help is-danger">
					Bitte wählen Sie eine Gruppe aus.
				</p>
				<ChildSelection :chosen-clients="chosenClients"
					@filter="id => chosenClients = chosenClients.filter(c => c.id !== id)" />
				<p v-if="!chosenClientsValid" class="help is-danger">
					Bitte wählen Sie mindestens einen Klienten aus.
				</p>
				<UserSelection :chosen-users="chosenUsers"
					@filter="id => chosenUsers = chosenUsers.filter(u => u.id !== id)" />
			</div>
			<hr>
			<div class="m-5 buttons" style="display: flex; justify-content: center">
				<SaveButton :is-loading="isLoading" :save-function="save"
					:disabled="$v.name.$invalid ||
						$v.num.$invalid || !accountListValid || !budgetListValid || !chosenManagerValid || !chosenGroupValid || !chosenClientsValid" />
				<CancelButton text="Abbrechen" back-route="/balance_overview" :is-loading="false" />
			</div>
		</div>
	</div>
</template>

<script>
import PanelHeader from "@/components/helperComponents/PanelHeader";
import InputWithValidation from "@/components/helperComponents/InputWithValidation";
import InputComponent from "@/components/helperComponents/InputComponent";
import SaveButton from "@/components/helperComponents/SaveButton";
import CancelButton from "@/components/helperComponents/CancelButton";
import BudgetList from "@/components/helperComponents/BudgetList";
import AccountList from "@/components/helperComponents/AccountList";
import GroupSelection from "@/components/balance/balanceGroup/GroupSelection";
import ChildSelection from "@/components/balance/balanceGroup/ChildSelection";
import Parse from "parse/dist/parse.min.js"
import UserSelection from "@/components/balance/balanceGroup/UserSelection";
import SingleUserSelection from "@/components/balance/balanceGroup/SingleUserSelection";
import { minLength, required } from "vuelidate/lib/validators";

export default {
	name: "NewBalanceGroup",
	components: {
		UserSelection,
		GroupSelection,
		ChildSelection,
		BudgetList,
		CancelButton,
		SaveButton,
		AccountList,
		InputWithValidation,
		PanelHeader,
		SingleUserSelection,
		InputComponent,
	},
	data() {
		return {
			name: "",
			description: "",
			num: undefined,
			gegenkonto: undefined,
			deadline: 5,
			accountList: [],
			accountListValid: false,
			budgetList: [],
			budgetListValid: false,
			isLoading: false,
			chosenClients: [],
			chosenClientsValid: true,
			chosenUsers: [],
			chosenManager: undefined,
			chosenManagerValid: false,
			chosenGroup: undefined,
			chosenGroupValid: false,
			value: 0,
			balanceGroup: undefined,
			previousBudgets: [],
			allowNegativeValue: false,
		}
	},
	created() {
		if (!this.$route.params.balanceGroup) return
		this.balanceGroup = this.$route.params.balanceGroup
		this.name = this.balanceGroup.attributes.name
		this.num = +this.balanceGroup.attributes.balanceNumber
		this.gegenkonto = +this.balanceGroup.attributes.gegenkonto
		this.deadline = this.balanceGroup.attributes.deadline
		this.description = this.balanceGroup.attributes.description
		this.allowNegativeValue = this.balanceGroup.attributes.allowNegativeAccountBalance
		for (var i = 0; i < this.balanceGroup.attributes.accounts.length; i++) {
			if (this.balanceGroup.attributes.accounts[i].token == undefined) {
				this.balanceGroup.attributes.accounts[i].token = "";
			}
		}

		this.accountList = this.balanceGroup.attributes.accounts

		this.chosenManager = this.balanceGroup.attributes.manager
		for (const budget of this.balanceGroup.attributes.budgets) {
			const budgetObject = {
				id: budget.id,
				name: budget.attributes.name,
				childSpecific: budget.attributes.childSpecific,
				budgetNumber: budget.attributes.budgetNumber,
				description: budget.attributes.description
			}
			this.budgetList.push(budgetObject)
			this.previousBudgets.push(budgetObject)
		}
		const entities = this.balanceGroup.attributes.entities
		this.chosenGroup = entities[entities.length - 1]
		entities.pop()
		this.chosenClients = entities
		this.chosenUsers = this.balanceGroup.attributes.users;
	},
	validations: {
		name: {
			required,
			minLength: minLength(1),
		},
		num: {
			required,
			minLength: minLength(1),
		},
	},
	watch: {
		accountList: {
			handler: function (newAccountList) {
				this.accountListValid = true;
				if (newAccountList.length != 0) {
					for (var i = 0; i < newAccountList.length; i++) {
						if (newAccountList[i].name == "" || newAccountList[i].token == "") {
							this.accountListValid = false;
						}
					}
				} else {
					this.accountListValid = false;
				}
			},
			deep: true
		},
		budgetList: {
			handler: function (newBudgetList) {
				this.budgetListValid = true;
				if (newBudgetList.length != 0) {
					for (var i = 0; i < newBudgetList.length; i++) {
						if (newBudgetList[i].name == "" || newBudgetList[i].budgetNumber == "") {
							this.budgetListValid = false;
						}
					}
				} else {
					this.budgetListValid = false;
				}

				this.chosenClientsValid = true;
				if (this.chosenClients.length == 0) {

					if (newBudgetList.length != 0) {
						for (var j = 0; j < newBudgetList.length; j++) {
							if (newBudgetList[j].childSpecific == true) {
								this.chosenClientsValid = false;
							}
						}
					}
				}
			},
			deep: true
		},
		chosenManager: {
			handler: function (newChosenManager) {
				this.chosenManagerValid = true;
				if (newChosenManager == undefined || newChosenManager == "") {
					this.chosenManagerValid = false;
				} else {
					this.chosenManagerValid = true;
				}
			},
			deep: true
		},
		chosenGroup: {
			handler: function (newChosenGroup) {
				this.chosenGroupValid = true;
				if (newChosenGroup == undefined || newChosenGroup == "") {
					this.chosenGroupValid = false;
				} else {
					this.chosenGroupValid = true;
				}
			},
			deep: true
		},
		chosenClients: {
			handler: function (newChosenClients) {
				this.chosenClientsValid = true;
				if (newChosenClients.length == 0) {

					if (this.budgetList.length != 0) {
						for (var i = 0; i < this.budgetList.length; i++) {

							if (this.budgetList[i].childSpecific == true) {
								this.chosenClientsValid = false;
							}
						}
					}
				}
			},
			deep: true
		},
	},
	methods: {
		async save() {		
			const BalanceGroup = new Parse.Object.extend("BalanceGroup")
			const balanceGroup = this.balanceGroup ? this.balanceGroup : new BalanceGroup()
			balanceGroup.set("name", this.name)
			balanceGroup.set("gegenkonto", this.gegenkonto)
			balanceGroup.set("deadline", String(this.deadline))
			balanceGroup.set("accounts", this.accountList)
			balanceGroup.set("description", this.description)
			balanceGroup.set("entities", [...this.chosenClients, this.chosenGroup])
			balanceGroup.set("users", this.chosenUsers)
			balanceGroup.set("manager", this.chosenManager)
			balanceGroup.set("allowNegativeAccountBalance", this.allowNegativeValue)
			const previousBudgetIds = this.previousBudgets.map(b => b.id)
			const budgetIds = this.budgetList.map(b => b.id)
			const deletedBudgets = this.previousBudgets.filter(b => !budgetIds.includes(b.id))

			for (const budget of deletedBudgets) {
				const budgetObjectQuery = new Parse.Query("Budget").equalTo("objectId", budget.id)
				const budgetObject = await budgetObjectQuery.first()
				budgetObject.set("deleted", true)
				budgetObject.save()
			}
			const newBudgets = this.budgetList.filter(b => !previousBudgetIds.includes(b.id))
			const previousBudgets = this.budgetList.filter(b => previousBudgetIds.includes(b.id))
			const budgets = []
			for (const budget of newBudgets) {
				const Budget = new Parse.Object.extend("Budget")
				const budgetObject = new Budget()
				budgetObject.set("name", budget.name)
				budgetObject.set("childSpecific", budget.childSpecific)
				budgetObject.set("budgetNumber", budget.budgetNumber)
				budgetObject.set("description", budget.description)
				const savedBudget = await budgetObject.save()
				budgets.push(savedBudget)
			}
			for (const budget of previousBudgets) {
				const budgetObjectQuery = new Parse.Query("Budget").equalTo("objectId", budget.id)
				const budgetObject = await budgetObjectQuery.first()
				if (!budgetObject) continue
				budgetObject.set("name", budget.name)
				budgetObject.set("childSpecific", budget.childSpecific)
				budgetObject.set("budgetNumber", budget.budgetNumber)
				budgetObject.set("description", budget.description)
				const savedBudget = await budgetObject.save()
				budgets.push(savedBudget)
			}
			balanceGroup.set("budgets", budgets)
			balanceGroup.set("balanceNumber", +this.num)
			balanceGroup.set("relatedGroup", this.chosenGroup.attributes.group_name)
			await balanceGroup.save();
			await this.$router.push({ name: "BalanceOverview" })
			
		},
	},
}
</script>

<style scoped>
.tableMatrix {
	width: 100%;
}

.table-header {
	padding-bottom: .75rem;
	border-bottom: 2px solid rgba(0, 0, 0, .1);
}

.table-footer {
	padding-bottom: .75rem;
	border-top: 2px solid rgba(0, 0, 0, .1);
}

.table-header>th {
	height: 40px;
	text-align: center;
	vertical-align: middle;
}

td {
	text-align: center;
	vertical-align: middle;
	height: 40px;
}

td,
th {
	min-width: 150px;
}


.has-shadow {
	border-radius: .5rem;
	border: 2px solid rgba(0, 0, 0, .8);
}

.overflow {
	width: 100%;
	max-height: 30rem;
	overflow: auto;
}

.borderContainer {
	border-radius: .5rem;
	padding: .5rem;
	margin: .5rem;
}

.w50 {
	width: 50%;
}

::v-deep .label {
	font-size: large;
}
</style>
